<template>
    <v-card outlined>
        <v-card-title class="pl-8 block-title">영업시간</v-card-title>
        <v-card-text class="pb-0 pl-0 pr-0">
            <v-row no-gutters class="align-center">
                <v-col cols="2" class="font-weight-bold d-flex justify-center" style="font-size: 20px;">기본업무시간</v-col>
                <v-col>
                    <div class="pl-4 pt-7 pb-4 pr-8 text-left rounded-0" style="border-left: thin solid rgba(0, 0, 0, 0.12);">
                        <v-row v-for="(item, idx) in workHours" v-bind:key="idx">
                            <v-col class="pt-0 pb-2">
                                <span style="font-size:18px; color:#333333;">{{ daysToStr(item.days) }}</span>
                                <span class="ml-5" style="font-size:18px; color:#333333;">{{ hourMinFormat(item.work_start) }} ~ {{ hourMinFormat(item.work_end) }}</span>
                                <v-btn icon color="grey" class="ml-2 align-baseline" @click="openModifyPopup(item, idx)">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="3" class="pl-0 pt-0 pb-2 text-right">
                                <v-btn icon color="grey" @click="delWorkHours(idx)" v-if="workHours.length > 1">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canAddWorkHours">
                            <v-col>
                                <v-btn outlined rounded class="text-h6" color="primary" @click="openAddPopup">
                                    <v-icon left dark size="25">mdi-plus-circle</v-icon>
                                    추가
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters class="align-center">
                <v-col cols="2" class="font-weight-bold d-flex justify-center align-baseline" style="font-size: 20px;">공휴일 휴무</v-col>
                <v-col style="border-left: thin solid rgba(0, 0, 0, 0.12);">
                    <div class="pa-4 text-left rounded-0" >
                        <v-row>
                            <v-col cols="12" class="pt-">
                                <v-switch inset color="#4E87DD" hide-details class="pt-0 mt-0" v-model="holidayUseYn" true-value="Y" false-value="N" @change="changeHolidayUseYn">
                                    <template v-slot:label>
                                        <span style="color:#999999; font-size:18px;" v-if="holidayUseYn == 'N'">공휴일 휴무 사용 안함</span>
                                        <span style="color:#999999; font-size:18px;" v-else>공휴일 휴무 사용</span>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="modal" persistent max-width="520" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'add'" style="width: 100%">
                <popup-work-hours v-on:submit="submitAdd" v-on:cancel="closePopup" :editMode="'add'"></popup-work-hours>
            </v-flex>
            <v-flex v-if="dialogMode === 'modify'">
                <popup-work-hours v-on:submit="submitModify" v-on:cancel="closePopup" :editMode="'modify'" :item-idx="modifyIdx"></popup-work-hours>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import {mapState} from 'vuex'

export default {
    name: 'svc_voice_intro_work_time',
    components: {
        'PopupWorkHours': () => import('@/components/svc/voice_intro/popup_work_hours.vue')
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        modifyIdx: -1,
        conf: {},
        workHoursUseYn: 'N',
        workHours: [],
        days: [],
        holidayUseYn: 'N',
    }),
    computed: {
        ...mapState('svcVoiceIntro', {
            workTimeConf: state => state.workTimeConf
        }),
        canAddWorkHours() {
            let allDays = ['0', '1', '2', '3', '4', '5', '6']
            this.workHours.forEach(workHoursItem => {
                let days = workHoursItem.days.split('|')
                if (days && days.length > 0) {
                    days.forEach(dayItem => {
                        let i = allDays.indexOf(dayItem)
                        if (i >= 0) {
                            allDays.splice(i, 1)
                        }
                    })
                }
            })
            return (allDays.length > 0)
        }
    },
    watch: {},
    created() {
    },
    mounted: function () {
        this.reload()
    },
    methods: {
        reload() {
            this.workHours = this.workTimeConf.workHours
            this.workHoursUseYn = this.workTimeConf.workHoursUseYn
            this.holidayUseYn = this.workTimeConf.holidayUseYn
        },
        apply() {
        },
        openAddPopup() {
            this.dialogMode = 'add'
            this.modal = true
        },
        openModifyPopup(item, idx) {
            this.modifyIdx = idx
            this.dialogMode = 'modify'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        submitAdd() {
            this.reload()
            this.closePopup()
        },
        submitModify() {
            this.reload()
            this.closePopup()
        },
        delWorkHours(idx) {
            this.$store.commit('svcVoiceIntro/delWorkHours', idx)
        },
        daysToStr(daysStr) {
            let str = ''
            let days = daysStr.split('|')
            let daysDic = [{txt: '일', val: '0'}, {txt: '월', val: '1'}, {txt: '화', val: '2'}, {txt: '수', val: '3'}, {txt: '목', val: '4'}, {txt: '금', val: '5'}, {txt: '토', val: '6'}]
            if (days) {
                daysDic.forEach(daysDicItem => {
                    days.forEach(item => {
                        if (item == daysDicItem.val) {
                            str = str + (str.length > 0 ? ' ' : '') + daysDicItem.txt
                        }
                    })
                })
            }

            return str
        },
        hourMinFormat(str) {
            let ret = ''
            if (str && str.length == 4) {
                ret = str.substring(0, 2) + ':' + str.substring(2)
            }
            return ret
        },
        changeHolidayUseYn() {
            this.$store.commit('svcVoiceIntro/setHolidayUseYn', this.holidayUseYn)
        },
    }
}
</script>